import "../assets/_global.scss";
import { Layout } from "../components/Layout/Layout";
import React from "react";
import { NewsBox } from "../components/NewsBox/NewsBox";

interface NewsPostBoxProps {
  pageContext: {
    language: string;
  };
}

const NewsPostBox: React.FC<NewsPostBoxProps> = ({
  pageContext: { language },
}) => {
  return (
    <NewsBox
      id="standalone-news-post-box"
      data={{ numberOfPosts: 3 }}
      language={language}
      appearance="navigationPage"
    />
  );
};

export default NewsPostBox;
